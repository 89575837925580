.tooltip {
  font-size: 12px;

  &.standby{
    z-index: -1;

    .tooltip-inner{
      background: transparent;
      color: color(font);
    }
  }
}

.tooltip-inner {
  padding: 7px 13px;
}
