
$color-primary: #353c48;
$color-primary-shadow: #353c48;
$color-primary-light: #353c48;
$color-inverted: #fff;

%common-prop-black {
	color: #fff;
	background-color: $color-primary;
	border-color: $color-primary;
}


.theme-black{
	&.sidebar-mini {
		.main-sidebar {
				.sidebar-menu>li{
					&.active>a{
						background-color: rgba(0,0,0,0.14);
					}
				}

			}
		}

	.bg-primary {
		background-color: $color-primary !important;
	}
    .bg-inverted {
        background: color(white);
        color: $color-primary;
        button {
            color: $color-primary;
        }
    }
	.text-primary {
		color: #fff !important;
	}
    .text-dark {
        color: #fff !important;
    }
	a:not([class*=btn-]):not([class=action]):not([class=enlarge]){
		color: #96a2b4;
		&:hover {
			color: #96a2b4;
			text-decoration: none;
		}
	}
	.portfolio-thumb {
		.action{
			&.disabled, &[disabled]{
				color: #96a2b4!important;
				cursor: default;
			}
		}
	}
	.btn-primary {
		background-color: #30353e;
		border-color: transparent !important;
		color: #fff;
		&:focus {
			background-color: #34373e !important;
			&:active {
				background-color: #34373e !important;
			}
		}
		&:active {
			background-color: #34373e !important;
		}
		&:hover {
			background-color: #34373e !important;
			color: #fff;
		}
		&.disabled {
			background-color: $color-primary;
			border-color: $color-primary;
		}
		&:disabled {
			background-color: $color-primary;
			border-color: $color-primary;
		}
	}
	.btn-outline-primary {
		color: $color-primary;
		background-color: transparent;
		background-image: none;
		border-color: $color-primary;
		&:hover {
			color: #fff;
			background-color: $color-primary;
			border-color: $color-primary;
		}
		&.disabled {
			color: $color-primary;
			background-color: transparent;
		}
		&:disabled {
			color: $color-primary;
			background-color: transparent;
		}
		&:not([disabled]):not(.disabled) {
			&:active {
				@extend %common-prop-black;
			}
			&.active {
				@extend %common-prop-black;
			}
		}
	}
  .btn-outline-primary:not(.btn){
    &:hover, &:focus, &:active {
      color: $color-primary !important;
      background-color: transparent !important;;
    }
  }
	.btn-link {
		font-weight: 400;
		//color: $color-primary-shadow;
		background-color: transparent;
		&:hover {
			color: $color-primary-light;
		}
	}
	.dropdown-item {
		&.active {
			color: #fff;
			background-color: $color-primary;
		}
		&:active {
			color: #fff;
			background-color: $color-primary;
		}
	}
	.nav-pills {
		.nav-link.active {
			color: #fff;
			background-color: $color-primary;
		}
		.show > .nav-link {
			color: #fff;
			background-color: $color-primary;
		}
	}
	.page-link {
		color: $color-primary;
		background-color: #fff;
		border: 1px solid #ededed;
		&:focus {
			color: $color-primary-light;
		}
		&:hover {
			color: $color-primary-light;
			background-color: #eaeaea;
		}
	}
	.page-item {
		.page-link {
			color: $color-primary;
		}
		&.active .page-link {
			color: #fff;
			background-color: $color-primary;
			border-color: $color-primary;
		}
		&.disabled{
			.page-link {
				color: $color-primary;
			}
		}
	}
	.progress-bar {
		color: #fff;
		background-color: $color-primary;
	}
	.border-primary {
		border-color: $color-primary !important;
	}
	.navbar-bg {
		background-color: $color-primary;
	}
	.jqvmap-circle {
		background-color: $color-primary;
		border: 1px solid #000;
	}
	.dropzone {
		border: 2px dashed $color-primary;
	}
	.show > .btn-outline-primary.dropdown-toggle {
		@extend %common-prop-black;
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		color: #fff;
		background-color: $color-primary;
	}
	.custom-checkbox .custom-control-input {
		&:checked ~ .custom-control-label::before {
			background-color: $color-primary;
		}
		&:indeterminate ~ .custom-control-label::before {
			background-color: $color-primary;
		}
	}
	.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
		background-color: $color-primary;
	}
	.list-group-item.active {
		color: #fff;
		background-color: $color-primary;
		border-color: $color-primary;
	}
	.navbar.active {
		background-color: $color-primary;
	}
	/*.form-control:focus {
		border-color: $color-primary;
	}*/
	.alert.alert-primary {
		background-color: $color-primary;
	}
	.card.card-primary {
		border-top: 2px solid $color-primary;
	}
	.fc button.fc-state-active {
		background-color: $color-primary;
		color: #fff;
	}
	.weather ul li {
		border: 2px solid $color-primary;
		color: $color-primary;
	}
	.card-chat .chat-content .chat-item.chat-right .chat-details .chat-text {
		background-color: $color-primary;
		color: #fff;
	}
	.nav-tabs .nav-item .nav-link {
		color: #fff; //$color-primary;
	}
	.nav-tabs .nav-item .nav-link.active {
		color: $color-primary;
	}
	.swal-button.swal-button--confirm {
		background-color: $color-primary;
	}
	.btn-group .btn.active {
		background-color: $color-primary;
		color: #fff;
	}
	.media .media-right {
		color: $color-primary;
	}
	.selectric-items li {
		&.selected {
			background-color: $color-primary;
			color: #fff;
		}
		&.highlighted {
			background-color: $color-primary;
			color: #fff;
		}
	}
	.accordion .accordion-header[aria-expanded="true"] {
		background-color: $color-primary;
		color: #fff;
	}
	.bootstrap-tagsinput .tag {
		background-color: $color-primary;
	}
	body:not(.sidebar-mini) .sidebar-style-2 {
		.sidebar-menu {
		> li {
			&.active {
			> a {
				&:before {
					background-color: $color-primary;
				}
			}
			}
		}

		}
	}
	body.sidebar-mini .main-sidebar .sidebar-menu > li {
		&.active > a {
			box-shadow: 0 4px 8px $color-primary-shadow;
			background-color: $color-primary;
			color: #fff;
		}
		ul.dropdown-menu li.active > a {
			color: $color-primary;
		}
	}
	.activities .activity:before {
		background-color: $color-primary;
	}
	.settingSidebar .settingPanelToggle{
		background: $color-primary;
		i{
			color: #fff;
		}
	}

  .text-body {
    color: $color-inverted!important;
  }
  .ReactModal__Content{
    background: #36414f!important;
    border-color: #585858 !important;

    [data-dismiss=modal] {
      background: gray;
    }
  }
  .ReactModal__Overlay {
    background-color: rgba(37, 37, 37, 0.8)!important;
  }

  .bg_color_title{
    color: white;
    box-shadow: inset 0 0 0 1px white;
  }

  .project_title_text {
    caret-color: white!important;
  }

	.sidebar-color .selectgroup-input:checked+.selectgroup-button{
		background-color: $color-primary;
	}
  .dt-button:not(:hover) {
    color: $color-inverted;
  }

	.custom-switch-input:checked ~ .custom-switch-indicator{
		background: $color-primary;
	}
	.selectgroup-input:focus+.selectgroup-button, .selectgroup-input:checked+.selectgroup-button{
		background-color: $color-primary;
	}
}
