.sm-gutters {
  margin-left: -5px;
  margin-right: -5px;
  > {
    .col, [class*=col-] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @each $key, $value in $gutters {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .row{
                &.g#{$infix}-#{$key},
                &.gx#{$infix}-#{$key} {
                    margin-right: #{- $value / 2};
                    margin-left: #{- $value / 2};
                }

                &.g#{$infix}-#{$key} > [class*=col-],
                &.gx#{$infix}-#{$key} > [class*=col-] {
                    padding-right: #{$value / 2};
                    padding-left: #{$value / 2};
                }

                &.g#{$infix}-#{$key} > [class*=col-],
                &.gy#{$infix}-#{$key} > [class*=col-] {
                    margin-top: #{$value};
                }
            }


        }
    }
}
