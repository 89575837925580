// change to only .main-wrapper
//.navbar {
//  //height: 70px;
//  left: 250px;
//  right: 0px;
//  position: absolute;
//  z-index: 890;
//  background-color: transparent;
//  &.active {
//    background-color: color(primary);
//    @include shadow(to_opacity(primary, .2));
//  }
//}

.main-wrapper {
  .main-navbar {
    height: 70px;

    left: 250px;
    right: 0px;
    position: absolute;
    z-index: 890;
    background-color: transparent;
    &.active {
      background-color: color(primary);
      @include shadow(to_opacity(primary, .2));
    }
  }
  &>.navbar-bg {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: color(primary);
    z-index: -1;
  }

}

.navbar {
  align-items: center;
  .navbar-brand {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
  }
  .form-inline {
    .form-control {
      background-color: #fff;
      border-color: transparent;
      padding-left: 20px;
      padding-right: 0;
      margin-right: -6px;
      min-height: 46px;
      font-weight: 500;
      border-radius: 3px 0 0 3px;
      transition: all 1s;
      &:focus,
      &:focus + .btn {
        position: relative;
        z-index: 9001;
      }
      &:focus + .btn + .search-backdrop {
        opacity: .6;
        visibility: visible;
      }
      &:focus + .btn + .search-backdrop + .search-result {
        opacity: 1;
        visibility: visible;
        top: 80px;
      }
    }
    .btn {
      border-radius: 0 3px 3px 0;
      background-color: #fff;
      padding: 9px 15px 9px 15px;
      border-color: transparent;
    }
    .search-element{
      .form-control {
        border-radius: 30px 0 0 30px;
      }
      .btn{
        border-radius: 0px 30px 30px 0px;
      }
    }
    .search-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9000;
      background-color: #000;
      opacity: 0;
      visibility: hidden;
      transition: all .5s;
    }
    .search-result {
      position: absolute;
      z-index: 9002;
      top: 100px;
      background-color: #fff;
      border-radius: 3px;
      width: 450px;
      opacity: 0;
      visibility: hidden;
      transition: all .5s;
      &:before {
        position: absolute;
        top: -26px;
        left: 34px;
        content: '\f0d8';
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
        color: #fff;
        font-size: 30px;
      }
      .search-header {
        padding: 13px 18px 2px 18px;
        text-transform: uppercase;
        letter-spacing: 1.3px;
        font-weight: 600;
        font-size: 10px;
        color: color_lighten(font, 30%);
      }
      .search-item {
        display: flex;
        a {
          display: block;
          padding: 13px 18px;
          text-decoration: none;
          color: color(fontdark);
          font-weight: 600;
          display: flex;
          align-items: center;
          &:hover {
            background-color: color_lighten(primary, 32%);
          }
          &:not(.search-close) {
            width: 100%;
          }
          i {
            margin-left: 0 !important;
          }
        }
        .search-icon {
          width: 35px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 50%;
        }
      }
    }
  }
  .active {
    .nav-link {
      color: #fff;
      font-weight: 700;
    }
  }
  .navbar-text {
    color: #fff;
  }
  .navbar-right{
    .nav-link {
      color: darken(#fff, 5%);
      padding-left: 15px !important;
      // top right menu need to override, cannot use !important
      padding-right: 15px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      line-height: 20px;
      display: flex;
      align-items: center;
      //justify-content: center;
      height: 100%;
      &.nav-link-lg {
        div {
          margin-top: 3px;
        }
        i {
          margin-left: 0 !important;
          font-size: 18px;
          line-height: 32px;
        }
      }
      &.nav-link-user {
        //color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 600;
        padding-right: 12px !important;
        img {
          width: 30px;
        }
        &:after{
          content: none;
        }
      }
      &.nav-link-img {
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 50%;
        overflow: hidden;
        .flag-icon {
          @include shadow;
          border-radius: 50%;
          line-height: 18px;
          height: 22px;
          width: 22px;
          background-size: cover;
        }
      }
    }
  }

  .dropdown:hover>.has-dropdown ~ .dropdown-menu{
    display: block;
  }
  .dropdown-menu{
    padding: 0;
    margin: 0;
    &>.dropdown {
      padding-top:10px;
      padding-bottom:10px;
    }

    >.dropdown-item{
      &:first-child{
        padding-top:20px;
      }
      &:last-child{
        padding-bottom:20px;
      }
      &>.dropdown-item{
        padding-top:0;
        padding-bottom:0;
      }
    }


    .dropdown-menu {
      left:100%;
      top: -10px;
    }
  }
}

.dropup .dropdown-menu{
  margin-bottom: 0;
}

.remove-caret:after {
  display: none;
}

.navbar .nav-link {
  line-height: normal;

  &:hover {
    color: #fff;
  }
  &.disabled {
    color: #fff;
    opacity: .6;
  }

  &::after {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.nav-collapse {
  display: flex;
}

@include media-breakpoint-down(xs) {
  body.search-show .navbar .form-inline .search-element {
    display: block;
  }
  .navbar {
    .form-inline {
      .search-element {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        z-index: 892;
        display: none;
        .form-control {
          float: left;
          border-radius: 3px 0 0 3px;
          width: calc(100% - 43px) !important;
        }
        .btn {
          margin-top: 1px;
          border-radius: 0 3px 3px 0;
        }
      }
      .search-result {
        width: 100%;
      }
      .search-backdrop {
        display: none;
      }
    }
    .nav-link.nav-link-lg div {
      display: none;
    }
  }
}

@include media-breakpoint-only(sm) {
  .navbar .form-inline .search-element {
    display: block;
  }
}
// don't know what is this for but it's breaking the normal navbar bootstrap on mobile
/*@include media-breakpoint-only(md) {
  .collapse {
    position: relative;
    .navbar-nav {
      position: absolute;
    }
  }
}*/

@media (max-width: 1024px) {
  .nav-collapse {
    position: relative;
    .navbar-nav {
      @include shadow(0 0 30px);
      position: absolute;
      top: 40px;
      left: 0;
      width: 200px;
      display: none;
      &.show {
        display: block;
      }
      .nav-item {
        &:first-child {
          border-radius: 3px 3px 0 0;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
        .nav-link {
          background-color: #fff;
          color: color(font);
          &:hover {
            background-color: color_lighten(light, 7.6%);
            color: color(primary);
          }
        }
        &:focus,
        &.active {
          > a {
            background-color: color(primary);
            color: #fff;
          }
        }
      }
    }
  }
  .main-navbar.navbar {
    left: 5px;
    right: 0;
    .dropdown-menu {
      position: absolute;
    }
    .navbar-nav {
      flex-direction: row;
    }
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
}
.app-dropdown {
	width: 280px !important;
}
.app-icon-dropdown {
	padding-left: 15px;
	li {
		display: inline-block;
		margin-top: 10px;
		margin-bottom: 10px;
		&:hover {
			background: rgba(181, 174, 174, 0.22);
		}
		a {
			display: inline-block;
			padding-bottom: 10px;
			padding-top: 10px;
			text-align: center;
			color: #878787;
			text-transform: capitalize;
			width: 80px;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.email-icon .material-icons {
		color: #71aa68;
		width: 100%;
	}
	.blog-icon .material-icons {
		color: #0080c0;
		width: 100%;
	}
	.cal-icon .material-icons {
		color: #ff8000;
		width: 100%;
	}
	.chat-icon .material-icons {
		color: #ff0080;
		width: 100%;
	}
	.gallery-icon .material-icons {
		color: #8000ff;
		width: 100%;
	}
	.profile-icon .material-icons {
		color: #008040;
		width: 100%;
	}
}
