.alert {
    &.border {
        &.alert-primary {
            border-color: color(primary) !important;;
        }
        &.alert-secondary {
            border-color: color(secondary) !important;
        }
        &.alert-success {
            border-color: color(success) !important;;
        }
        &.alert-info {
            border-color: color(info) !important;;
        }
        &.alert-warning {
            border-color: color(warning) !important;;
        }
        &.alert-danger {
            border-color: color(danger) !important;;
        }
        &.alert-light {
            border-color: color(light) !important;;
        }
        &.alert-dark {
            border-color: color(dark) !important;;
        }
    }
    &.alert-light {
        background-color: color(light);
        color: color(dark);
    }
    &.alert-dark {
        background-color: color(dark);
    }
}
